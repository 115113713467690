<template>
    <div>
        <div v-if="authUserPermission['department-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('department') }}</h3>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center" v-model="table.search" :placeholder="tt('search_department')" v-on:keyup="filter"/>                            
                                </div>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['department-create']">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('holding')" :prop="tt('holding')" sortable>
                                <template v-slot="{row}">
                                    {{row.client_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('code')" :prop="tt('code')" sortable>
                                <template v-slot="{row}">
                                    {{row.code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('description')" :prop="tt('description')" sortable>
                                <template v-slot="{row}">
                                    {{row.description}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['department-update'] || authUserPermission['department-delete']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['department-update']">Edit</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['department-delete']">Delete</el-dropdown-item>                                        
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('holding') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('holding')" rules="required">
                            <el-select class="select-danger" v-model="department.client_code" :placeholder="tt('choose_holding')">
                                <el-option class="select-danger" :value="hc['client_code']" :label="hc['client_code'] + ' - ' + hc['client_name']" :key="hc['client_code'] + ' - ' + hc['client_name']" v-for="hc in  holdingCode">{{ hc['client_code'] }} - {{ hc['client_name'] }}</el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('code')" :placeholder="tt('code')" v-model="department.code" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('description')" :placeholder="tt('description')" v-model="department.description" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import department from '@/services/manage/department.service';
    import holdingCode from '@/services/master/holdingCode.service';
    
    export default {        
        data() {
            return {                 
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },             
                form: {
                    add: true,
                    title: "Create Department",
                    show: false
                }, 
                table: {
                    search: '',                    
                    total: 0,
                    data: []
                },     
                errorMessage: null,   
                department: {},
                holdingCode: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.getHoldingCode()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, department.get({search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;  
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                    } else {
                        alert('failed')
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            getHoldingCode() {
                let context = this;               
                Api(context, holdingCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.holdingCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.holdingCode = [];
                    }
                })
                .call()
            },
            filter() {
                this.onLoad = true;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 1000);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_department');
                this.form.show = true;
                this.department = {};
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, department.show(id)).onSuccess(function(response) {
                    context.department = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('edit_department');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {           
                let context = this;           
                let api = null;
                context.btnSave.onLoading = true;
                context.onLoad = true;

                if (context.form.add) {
                    api = Api(context, department.create(context.department));
                } else {{
                    api = Api(context, department.update(context.department.id, context.department));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;  
                    context.form.show = false;
                    context.onLoad = false; 
                })
                .call();
            },
            remove(id) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        context.onLoad = true
                        Api(context, department.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
        }   
    };
</script>
<style></style>
